import type { EntOffersType } from "@/types/EntCompanyTypes";
import type { SelectionType } from "@/types/profileSharingTypes";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { trash2Outline } from "@assets/Icons";

import { buildTooltipFromStackErrors } from "@tools/Errors";
import { stringifyRequestInputs } from "@tools/Objects";
import { checkIfOfferCouldBeCreated } from "@tools/Offers";
import { extractIdNumberFromId } from "@tools/Users";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { Spinner } from "@designSystem/atoms/Spinner";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { errorToast, success } from "@tools/Toasts";

import { useSearchParams } from "react-router-dom";
import {
	createSelection,
	updateEntOffer,
} from "../../../Partners/core/api/Partners.api";
import {
	clearDataFetched,
	resyncTargetOffer,
	setOfferToCreate,
	setOfferToEdit,
} from "../../core/store/offersManagement.slice";
import {
	fetchCreateOffer,
	fetchDeleteOffer,
	fetchModifyOffer,
} from "../../core/store/offersManagement.thunks";

type CreationHeaderProps = {
	editionMode?: boolean;
};

export function OfferCreationActions({ editionMode }: CreationHeaderProps) {
	const [params] = useSearchParams();
	const dispatchEvent = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { offerToEdit, offerToCreate, loadingStates } = useAppSelector(
		(state) => state.offers,
	);

	const getu = (defaultUrl: string) => {
		const u = params.get("u");
		if (u) {
			return u;
		}
		return defaultUrl;
	};

	const handleAbortCreation = () => {
		if (editionMode) {
			dispatchEvent(setOfferToEdit(offerToCreate));
		}
		dispatchEvent(clearDataFetched());
		dispatchEvent(setOfferToCreate(undefined));
		navigate(
			getu(
				editionMode && offerToEdit
					? `/offers/details/${extractIdNumberFromId(offerToEdit?.id)}`
					: "/offers",
			),
		);
	};

	const handleDeleteOffer = () => {
		dispatchEvent(fetchDeleteOffer(offerToEdit?.id) as any);
		dispatchEvent(clearDataFetched());
		dispatchEvent(setOfferToCreate(undefined));
		dispatchEvent(setOfferToEdit(undefined));

		success("L'offre a bien été supprimée");

		navigate(getu("/offers"));
	};

	const handleCreation = async (entOffer?: string) => {
		try {
			const newOffer = await dispatchEvent(
				fetchCreateOffer({
					offerData: offerToCreate || {},
				}) as any,
			);
			const newOfferId = newOffer.payload.data.createOffer.offer.id;
			if (entOffer) {
				const entOfferUpdate: Partial<EntOffersType> = {
					id: entOffer,
					status: "active",
					spsOffer: newOfferId,
				};
				await updateEntOffer(entOfferUpdate);
				if (offerToCreate.type === "direct") {
					const newSelection: Partial<SelectionType> = {
						entOffer,
						name: offerToCreate.job,
					};
					await createSelection(stringifyRequestInputs(newSelection));
				}
				navigate("/partners/offers");
			} else navigate("/offers");
		} catch {
			errorToast("Une erreur est survenue");
		}
	};

	const handleModification = async () => {
		const updatedOffer = { ...offerToCreate };
		updatedOffer.updateAt = new Date().toISOString();
		dispatchEvent(resyncTargetOffer(updatedOffer));

		await dispatchEvent(
			fetchModifyOffer({
				offerData: offerToCreate || {},
			}) as any,
		);
		if (editionMode) {
			dispatchEvent(setOfferToEdit(offerToCreate));
		}
		dispatchEvent(clearDataFetched());
		dispatchEvent(setOfferToCreate(undefined));
		navigate(
			getu(`/offers/details/${extractIdNumberFromId(offerToCreate?.id)}`),
		);
	};
	return (
		<article
			data-testid="offerCreationActions"
			className={`flex w-full items-center ${
				editionMode ? "justify-between" : "justify-end"
			}`}
		>
			{editionMode && (
				<div data-testid="deleteButton" className="">
					<InlineButton
						id="deleteButton"
						label="Supprimer l'offre"
						icon={trash2Outline}
						iconPosition="left"
						className="[&>*]:!text-error-dark"
						onClick={() => {
							handleDeleteOffer();
						}}
					/>
				</div>
			)}
			<div className="flex gap-4">
				{editionMode && (
					<div className="max-w-[105px]" data-testid="abortButton">
						<ButtonSecondary
							label="Annuler"
							onClick={() => handleAbortCreation()}
						/>
					</div>
				)}
				<TooltipContainer
					anchorId={"#creationbutton"}
					makeClickable={false}
					children={buildTooltipFromStackErrors(
						checkIfOfferCouldBeCreated(offerToCreate, editionMode),
					)}
					place="top"
				/>
				{loadingStates.isCreatingOffer ? (
					<Spinner size="small" />
				) : (
					<>
						<ButtonPrimary
							id="creationbutton"
							label={
								editionMode ? "Publier les modifications" : "Publier l'offre"
							}
							onClick={() => {
								editionMode
									? handleModification()
									: handleCreation(state?.entOffer ? state.entOffer.id : null);
							}}
							disabled={
								checkIfOfferCouldBeCreated(offerToCreate, editionMode)
									?.length !== 0
							}
						/>
						{checkIfOfferCouldBeCreated(offerToCreate, editionMode)?.length !==
							0 && <TooltipElement id="creationbutton" type="alert" />}
					</>
				)}
			</div>
		</article>
	);
}
