import type { CampusType } from "@/types/campusTypes";
import type { UserType } from "@/types/userTypes";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import oktaConfig from "@config/oktaConfig";
import { OktaAuth } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import React, { createContext, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "@assets/sass/classes/index.scss";

import { getSubdomain, logout } from "@tools/Auth";
import { getCookie, setCookie } from "@tools/Cookies";

import { Spinner } from "@designSystem/atoms/Spinner";

/**
 * Auth pages
 */
import { Activate } from "@containers/auth/Activate";
import { ChangePassword } from "@containers/auth/ChangePassword";
import { ForgotPassword } from "@containers/auth/ForgotPassword";
import { Login } from "@containers/auth/Login";
import { LoginMicrosoft } from "@containers/auth/LoginMicrosoft";
import { LoginOkta } from "@containers/auth/LoginOkta";
import NotFoundPublic from "@containers/public/404";
import { InscriptionForm } from "@containers/public/InscriptionForm";
import { Selection } from "@containers/public/Selection";
import { CVBoard } from "@containers/school/CVBoard";
/**
 * Schools pages
 */
import { Contacts } from "@containers/school/Contacts";
import { EventsSchoolCreation } from "@containers/school/Events/ui/EventsSchoolCreationView";
import { EventsSchoolView } from "@containers/school/Events/ui/EventsSchoolView";
import MailBox from "@containers/school/Mailbox";
import { OffersView } from "@containers/school/Offers_v3/pages/OffersBoard.page";
import { OffersDetailsView } from "@containers/school/Offers_v3/pages/OffersDetails.page";
import { PartnersIndex } from "@containers/school/Partners/pages/Partners.page";
import { Promotions } from "@containers/school/Promotions";
import { ResourceEditor } from "@containers/school/Resources/ResourceEditor";
import { SchoolDashboard } from "@containers/school/SchoolDashboard";
import { ContactUs } from "@containers/school/SchoolHelpCenter/ContactUs";
import { News } from "@containers/school/SchoolHelpCenter/News";
import { SchoolHelp } from "@containers/school/SchoolHelpCenter/SchoolHelp";
import { SendMail } from "@containers/school/SendMail";
import { StudentDetail } from "@containers/school/Students/StudentDetail";
import { StudentsManagement } from "@containers/school/Students/StudentsManagement";
import { TagsManagement } from "@containers/school/TagsManagement";
import { Coaching } from "@containers/shared/Coaching";
import { Community } from "@containers/shared/Community";
import { AccountDisabled } from "@containers/shared/Errors/AccountDisabled";
import { ErrorInternalServerError } from "@containers/shared/Errors/ErrorInternalServerError";
import { ErrorNoAccess } from "@containers/shared/Errors/ErrorNoAccess";
/**
 * Shared pages
 */
import { ErrorNotFound } from "@containers/shared/Errors/ErrorNotFound";
import { OfferExpired } from "@containers/shared/Errors/OfferExpired";
import TestSoftr from "@containers/shared/PageTest/TestSoftr";
import { Parameters } from "@containers/shared/Parameters";
import { ResourceDetail } from "@containers/shared/ResourceDetail";
import { Resources } from "@containers/shared/Resources";
import { SpontaneousApplication } from "@containers/shared/SpontaneousApplication";
/**
 * Students pages
 */
import { ApplicationDetail } from "@containers/student/Applications/ApplicationDetail";
import { ApplicationsManagement } from "@containers/student/Applications/ApplicationsManagement";
import { Discover } from "@containers/student/Discover";
import { EventsStudentView } from "@containers/student/Events/ui/EventsStudentView";
import { Jobboard } from "@containers/student/Jobboard/pages/Jobboard";
import { OfferDetail } from "@containers/student/Jobboard/pages/OfferDetail";
import { MentorGoalAcademy } from "@containers/student/MentorGoalAcademy";
import { Onboarding } from "@containers/student/Onboarding";
import { StudentDashboard } from "@containers/student/StudentDashboard";
import { StudentHelp } from "@containers/student/StudentHelp";

/**
 * Business pages
 */

import { SchoolStatistics } from "@containers/school/SchoolStatistics";
import { changeAppLanguage } from "@tools/ChangeAppLanguage";
import {
	aceConfig,
	amosConfig,
	cmhConfig,
	esdacConfig,
	esieaConfig,
} from "../config/azureAuthConfig";
import { AuthLayout } from "../containers/auth/AuthLayout";
import { LoginAce } from "../containers/auth/Login/LoginAce";
import { LoginCompany } from "../containers/auth/LoginCompany";
import { CompanyForgotPassword } from "../containers/auth/RegisterCompany/ForgotPassword";
import { MailSent } from "../containers/auth/RegisterCompany/MailSent";
import { CompanyHomepage } from "../containers/company";
import { CompanyCVBoard } from "../containers/company/CVBoard";
import { CompanyLayout } from "../containers/company/Layout";
import { Selections } from "../containers/company/Offers";
import { CreateOffer } from "../containers/company/Offers/Create";
import { CompanyUsers } from "../containers/company/Users";
import { SelectionStudentDetails } from "../containers/public/Selection/SelectionStudentDetails";
import { Ats } from "../containers/school/Ats";
import { AtsLayout } from "../containers/school/Ats/AtsLayout";
import { ProfileSharing } from "../containers/school/ProfileSharing";
import { SchoolLayout } from "../containers/school/SchoolLayout/SchoolLayout";
import Prepera from "../containers/shared/Prepera";
import { StudentLayout } from "../containers/student/StudentLayout/StudentLayout";

import { CompanyDetails } from "@containers/school/Companies/pages/details/CompanyDetails.page";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { DependenciesProvider } from "src/providers/DepenciesProvider";

import { EStatus } from "@/types/userTypes";
import { app } from "@config/main";
import { SOFTR_MAPPING } from "@constants/Softr";
import { ChooseProfile } from "@containers/auth/ChooseProfile";
import { RegisterCompany } from "@containers/auth/RegisterCompany";
import { CompaniesBoard } from "@containers/school/Companies/pages/board/CompaniesBoard.page";
import { OfferEditorView } from "@containers/school/Offers_v3/pages/OfferEditor.page";
import { AssistedGeneration } from "@containers/student/AssistedGeneration";
import { SoftrViewer } from "@modules/softr/SoftrViewer";
import { getAuthenticatedUser } from "@tools/API";

export type GlobalContextType = {
	user: UserType | undefined;
	setUser: Function;
	campuses: CampusType[];
	setCampuses: Function;
	menuActiveNavigation: boolean;
	setMenuActiveNavigation: Function;
	currentInterface: "student" | "school" | "company" | null;
	setCurrentInterface: Function;
	slidingPaneIsOpen: boolean;
	setSlidingPaneIsOpen: (isOpen: boolean) => void;
};
export const GlobalContext = createContext<GlobalContextType>({
	user: undefined,
	setUser: () => {},
	campuses: [],
	setCampuses: () => {},
	menuActiveNavigation: false,
	setMenuActiveNavigation: () => {},
	currentInterface: null,
	setCurrentInterface: () => {},
	slidingPaneIsOpen: false,
	setSlidingPaneIsOpen: () => {},
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function Router() {
	const [user, setUser] = useState<UserType>();
	const [campuses, setCampuses] = useState<CampusType[]>([]);
	const [displayAceInterface, setDisplayAceInterface] = useState(false);
	const [isMicrosoftLogin, setIsMicrosoftLogin] = useState(false);
	const [accountIsDisabled, setAccountIsDisabled] = useState(false);
	const esieaInstance = new PublicClientApplication(esieaConfig);
	const amosInstance = new PublicClientApplication(amosConfig);
	const esdacInstance = new PublicClientApplication(esdacConfig);
	const cmhInstance = new PublicClientApplication(cmhConfig);
	const aceInstance = new PublicClientApplication(aceConfig);
	const [usedInstance, setUsedInstance] = useState(amosInstance);
	const [authInProgress, setAuthInProgress] = useState(true);
	const [menuActiveNavigation, setMenuActiveNavigation] = useState(false);
	const [slidingPaneIsOpen, setSlidingPaneIsOpen] = useState(false);
	const [currentInterface, setCurrentInterface] = useState<
		"student" | "school" | null
	>(null);

	const oktaAuth = new OktaAuth(oktaConfig);

	const auth = async (token: string | null = getCookie("token")) => {
		if (token) {
			if (!getCookie("interface")) {
				logout();
			}
			const data: any = await getAuthenticatedUser(token).catch(() => {
				logout(
					false,
					getCookie("interface") === "company" ? "/company" : undefined,
				);
			});
			setUser(data);
			Sentry.setUser({
				id: data.id,
				email: data.email,
				username: `${data.firstname} ${data.lastname}`,
				ip_address: getCookie("ip") || undefined,
			});
			if (data.type !== getCookie("interface")) {
				window.open("/");
			}

			if (getCookie("interface") === "student") {
				setAccountIsDisabled(data.status === EStatus.SURREND);
			}
			changeAppLanguage(data.choosedLanguage || "fr");
			if (data.newLogsCount) {
				setCookie("newLogsCount", data.newLogsCount.toString());
			}
		}
	};

	const defineInstance = (string: string) => {
		if (string.includes("cmh")) {
			setUsedInstance(cmhInstance);
		}
		if (string.includes("esiea") || string.includes("intechinfo")) {
			setUsedInstance(esieaInstance);
		}
		if (string.includes("esdac")) {
			setUsedInstance(esdacInstance);
		}
		if (string.includes("sso-ace")) {
			setUsedInstance(aceInstance);
		}
		return null;
	};

	const fetchIp = async () => {
		if (getCookie("ip")) return;

		const response = await axios.get("https://api.ipify.org?format=json");
		setCookie("ip", response.data.ip);
	};

	useEffect(() => {
		const subdomain = getSubdomain();

		fetchIp();

		if (subdomain === "ace") {
			setDisplayAceInterface(true);
		}
		if (!user && getCookie("token")) {
			setAuthInProgress(true);
			auth(getCookie("token"));
		} else {
			setAuthInProgress(false);
		}
	}, [user]);
	return (
		<Provider store={app.store}>
			<DependenciesProvider dependencies={app.dependencies}>
				<GlobalContext.Provider
					value={{
						user,
						setUser,
						campuses,
						setCampuses,
						menuActiveNavigation,
						setMenuActiveNavigation,
						currentInterface,
						setCurrentInterface,
						slidingPaneIsOpen,
						setSlidingPaneIsOpen,
					}}
				>
					<BrowserRouter>
						{!authInProgress ? (
							<SentryRoutes>
								<Route path="/">
									{!user ? (
										<>
											<Route
												path="/"
												element={
													<AuthLayout
														defineInstance={defineInstance}
														setIsMicrosoftLogin={setIsMicrosoftLogin}
													/>
												}
											>
												<Route
													path="/choose_profile"
													element={<ChooseProfile />}
												/>
												<Route
													path="/login/okta"
													element={
														<Security
															oktaAuth={oktaAuth}
															restoreOriginalUri={() => {}}
														>
															<LoginOkta />
														</Security>
													}
												/>

												<Route
													path="/okta/callback"
													element={
														<Security
															oktaAuth={oktaAuth}
															restoreOriginalUri={async () => {
																window.location.replace("/login/okta");
															}}
														>
															<LoginCallback />
														</Security>
													}
												/>

												<Route
													index
													element={
														isMicrosoftLogin ? (
															<MsalProvider instance={usedInstance}>
																<LoginMicrosoft
																	setIsMicrosoftLogin={setIsMicrosoftLogin}
																/>
															</MsalProvider>
														) : displayAceInterface ? (
															<LoginAce
																setDisplayAceInterface={setDisplayAceInterface}
															/>
														) : (
															<Login
																defineInstance={defineInstance}
																setIsMicrosoftLogin={setIsMicrosoftLogin}
															/>
														)
													}
												/>
												<Route
													path="forgot_password"
													element={
														<ForgotPassword
															isMicrosoftLoginState={{
																isMicrosoftLogin,
																setIsMicrosoftLogin,
															}}
														/>
													}
												/>
												<Route
													path="activate"
													element={
														<Activate
															isMicrosoftLoginState={{
																isMicrosoftLogin,
																setIsMicrosoftLogin,
															}}
														/>
													}
												/>
												<Route
													path="change_password/:token"
													element={<ChangePassword />}
												/>
												<Route
													path="welcome/:token"
													element={<ChangePassword />}
												/>
												<Route
													path="500"
													element={<ErrorInternalServerError />}
												/>
												<Route path="403" element={<ErrorNoAccess />} />
												<Route path="*" element={<ErrorNotFound />} />
											</Route>
											<Route path="/company" element={<CompanyLayout />}>
												<Route path="login" element={<LoginCompany />} />
												<Route
													path="forgot_password"
													element={<CompanyForgotPassword />}
												/>
												<Route
													path="set_password"
													element={<CompanyForgotPassword />}
												/>
												<Route
													path="change_password/:token"
													element={<ChangePassword />}
												/>
												<Route path="register" element={<RegisterCompany />} />
												<Route index element={<CompanyHomepage />} />
											</Route>
										</>
									) : (
										<Route path="/">
											{user.roles.includes("ROLE_CAMPUS_ADMIN") &&
												user.roles.includes("ROLE_ATS") && (
													<Route path="/" element={<AtsLayout />}>
														<Route path="ats" element={<Ats />} />
													</Route>
												)}
											{(user.roles.includes("ROLE_CAMPUS_USER") ||
												user.roles.includes("ROLE_SCHOOL") ||
												user.roles.includes("ROLE_CAMPUS_ADMIN")) && (
												<Route path="/" element={<SchoolLayout />}>
													<Route index element={<SchoolDashboard />} />
													<Route
														path="statistics"
														element={<SchoolStatistics />}
													/>
													<Route path="mail-box" element={<MailBox />} />
													<Route
														path="events"
														element={
															user?.isClient ? (
																<EventsSchoolView />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("events")?.templateID ||
																		""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("events")?.subdomain || ""
																	}
																/>
															)
														}
													/>
													<Route
														path="event-creation"
														element={
															user?.isClient ? (
																<EventsSchoolCreation />
															) : (
																<SoftrViewer
																	templateID="5b0a835d-bf79-4991-b80a-0e9fdfafb656"
																	subdomain="lacresha357"
																/>
															)
														}
													/>
													<Route
														path="dashboard"
														element={<SchoolDashboard />}
													/>
													<Route
														path="students"
														element={<StudentsManagement />}
													/>
													<Route
														path="students/:id"
														element={<StudentDetail />}
													/>
													<Route
														path="links"
														element={
															user?.isClient ? (
																<ProfileSharing />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("relations")
																			?.templateID || ""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("relations")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route path="promotions" element={<Promotions />} />
													<Route
														path="cvboard"
														element={
															user?.isClient ? (
																<CVBoard />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("relations")
																			?.templateID || ""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("relations")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="cvboard/:id"
														element={
															user?.isClient ? (
																<CVBoard />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("relations")
																			?.templateID || ""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("relations")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="contacts"
														element={
															user?.isClient ? (
																<Contacts />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("prospection")
																			?.templateID || ""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("prospection")
																			?.subdomain || ""
																	}
																/>
															)
														}
													/>
													<Route
														path="offers/creation"
														element={
															user?.isClient ? (
																<OfferEditorView editionMode={false} />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("relations")
																			?.templateID || ""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("relations")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="offers/edition/:id"
														element={
															user?.isClient ? (
																<OfferEditorView editionMode={true} />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("relations")
																			?.templateID || ""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("relations")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="offers/details/:id"
														element={
															user?.isClient ? (
																<OffersDetailsView />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("relations")
																			?.templateID || ""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("relations")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="offers/details/:id"
														element={
															user?.isClient ? (
																<OffersDetailsView />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("relations")
																			?.templateID || ""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("relations")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="offers"
														element={
															user?.isClient ? (
																<OffersView />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("relations")
																			?.templateID || ""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("relations")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="crm-companies"
														element={<CompaniesBoard />}
													/>
													<Route
														path="crm-company/:id"
														element={<CompanyDetails />}
													/>
													<Route path="school-help" element={<SchoolHelp />} />
													<Route
														path="school-student-help"
														element={<StudentHelp />}
													/>
													<Route path="contact-us" element={<ContactUs />} />
													<Route path="news" element={<News />} />
													<Route
														path="school-resources"
														element={
															user?.isClient ? (
																<Resources />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("coaching")?.templateID ||
																		""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("coaching")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="school-resources-cv-models"
														element={
															user?.isClient ? (
																<Resources defaultTab="cvModels" />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("coaching")?.templateID ||
																		""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("coaching")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="school-resources-motivation-letter"
														element={
															user?.isClient ? (
																<Resources defaultTab="motivationLetterModels" />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("coaching")?.templateID ||
																		""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("coaching")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="school-resources/create"
														element={
															user?.isClient ? (
																<ResourceEditor />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("coaching")?.templateID ||
																		""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("coaching")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="school-resources/edit/:id"
														element={
															user?.isClient ? (
																<ResourceEditor />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("coaching")?.templateID ||
																		""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("coaching")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="school-resources/:id"
														element={
															user?.isClient ? (
																<ResourceDetail />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("coaching")?.templateID ||
																		""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("coaching")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route path="tags" element={<TagsManagement />} />
													<Route
														path="school-coaching"
														element={
															user?.isClient ? (
																<Coaching />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("coaching")?.templateID ||
																		""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("coaching")?.subdomain ||
																		""
																	}
																/>
															)
														}
													/>
													<Route
														path="school-community"
														element={<Community />}
													/>
													<Route
														path="school-spontaneous-application"
														element={
															user?.isClient ? (
																<SpontaneousApplication />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("prospection")
																			?.templateID || ""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("prospection")
																			?.subdomain || ""
																	}
																/>
															)
														}
													/>
													<Route
														path="school-network"
														element={
															user?.isClient ? (
																<SpontaneousApplication />
															) : (
																<SoftrViewer
																	templateID={
																		SOFTR_MAPPING.get("prospection")
																			?.templateID || ""
																	}
																	subdomain={
																		SOFTR_MAPPING.get("prospection")
																			?.subdomain || ""
																	}
																/>
															)
														}
													/>
													<Route
														path="school-parameters"
														element={<Parameters />}
													/>
													<Route
														path="school-mentor-goal-academy"
														element={<MentorGoalAcademy />}
													/>
													<Route
														path="school-jobboard"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<Jobboard />
															)
														}
													/>
													<Route
														path="own-offers"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<Jobboard />
															)
														}
													/>
													<Route
														path="school-extension"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<Jobboard />
															)
														}
													/>
													<Route
														path="school-jobboard/:id"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<OfferDetail />
															)
														}
													/>
													<Route path="partners" element={<PartnersIndex />} />
													<Route
														path="partners/:tab"
														element={<PartnersIndex />}
													/>
													<Route
														path="500"
														element={<ErrorInternalServerError />}
													/>
													<Route path="*" element={<ErrorNotFound />} />
													<Route path="403" element={<ErrorNoAccess />} />
												</Route>
											)}
											{user.roles.includes("ROLE_MG") && (
												<Route path="/" element={<StudentLayout />}>
													<Route path="test-softr" element={<TestSoftr />} />
													<Route path="send-mail" element={<SendMail />} />
												</Route>
											)}
											{user.roles.includes("ROLE_STUDENT") && (
												<Route path="/" element={<StudentLayout />}>
													<Route index element={<StudentDashboard />} />
													<Route
														path="profile"
														element={<StudentDashboard />}
													/>
													<Route
														path="events-student"
														element={<EventsStudentView />}
													/>
													<Route
														path="events-student/:id"
														element={<EventsStudentView />}
													/>
													<Route
														path="student-help"
														element={<StudentHelp />}
													/>
													<Route path="onboarding" element={<Onboarding />} />
													<Route path="discover" element={<Discover />} />
													<Route
														path="applications"
														element={<ApplicationsManagement />}
													/>
													<Route
														path="student-spontaneous-application"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<SpontaneousApplication />
															)
														}
													/>
													<Route
														path="student-network"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<SpontaneousApplication />
															)
														}
													/>
													<Route
														path="applications/:id"
														element={<ApplicationDetail />}
													/>
													<Route
														path="resources"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<Resources />
															)
														}
													/>
													<Route
														path="cv-models"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<Resources defaultTab="cvModels" />
															)
														}
													/>
													<Route
														path="motivation-letter"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<Resources defaultTab="motivationLetterModels" />
															)
														}
													/>
													<Route
														path="resources/:id"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<ResourceDetail />
															)
														}
													/>
													<Route
														path="jobboard"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<Jobboard />
															)
														}
													/>
													<Route
														path="school-offers"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<Jobboard />
															)
														}
													/>
													<Route
														path="/extension"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<Jobboard />
															)
														}
													/>
													<Route
														path="jobboard/:id"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<OfferDetail />
															)
														}
													/>
													<Route
														path="offer-expired"
														element={
															accountIsDisabled ? (
																<AccountDisabled />
															) : (
																<OfferExpired />
															)
														}
													/>
													<Route
														path="student-coaching"
														element={<Coaching />}
													/>
													<Route
														path="mentor-goal-academy"
														element={<MentorGoalAcademy />}
													/>
													<Route
														path="student-community"
														element={<Community />}
													/>
													<Route path="prepera" element={<Prepera />} />
													<Route
														path="student-parameters"
														element={<Parameters />}
													/>
													<Route
														path="assisted-generation"
														element={<AssistedGeneration />}
													/>
													<Route
														path="500"
														element={<ErrorInternalServerError />}
													/>
													<Route path="*" element={<ErrorNotFound />} />
													<Route path="403" element={<ErrorNoAccess />} />
												</Route>
											)}
											<Route path="/" element={<CompanyLayout />}>
												<Route index element={<CompanyHomepage />} />
												<Route path="company/">
													<Route
														path="company-cv-board"
														element={<CompanyCVBoard />}
													/>
													<Route
														path="company-selections"
														element={<Selections />}
													/>

													<Route index element={<CompanyHomepage />} />
													<Route
														path="company-create"
														element={<RegisterCompany />}
													/>
													<Route
														path="company-create/sent"
														element={<MailSent />}
													/>
													<Route
														path="company-offers"
														element={<RegisterCompany />}
													/>
													<Route
														path="company-offers/create"
														element={<CreateOffer />}
													/>
													<Route
														path="company-users"
														element={<CompanyUsers />}
													/>
												</Route>
											</Route>
										</Route>
									)}
									<Route path="/selection">
										<Route path={":school/:token"}>
											<Route index element={<Selection />} />
											<Route
												path={"users/:id"}
												element={<SelectionStudentDetails />}
											/>
										</Route>
										<Route path="*" element={<NotFoundPublic />} />
									</Route>
									<Route
										path="/inscriptionForm"
										element={<InscriptionForm />}
									/>
								</Route>
							</SentryRoutes>
						) : (
							<div className="flex h-screen w-screen items-center justify-center">
								<Spinner />
							</div>
						)}
					</BrowserRouter>
				</GlobalContext.Provider>
			</DependenciesProvider>
		</Provider>
	);
}
