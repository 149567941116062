import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { checkEmail } from "@tools/Checkers";
import { setCookie } from "@tools/Cookies";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { Spinner } from "@designSystem/atoms/Spinner";
import { Input } from "@designSystem/molecules/Input";
import { PasswordInput } from "@designSystem/molecules/PasswordInput";
import { WhiteCard } from "@designSystem/molecules/WhiteCard";
import { errorToast } from "@tools/Toasts";

import { useAppSelector } from "@config/redux/hook";
import type { AppState } from "@config/redux/store";
import type { ProfileType } from "@containers/auth/ChooseProfile";
import { createEntEmployee } from "@containers/auth/RegisterCompany/RegisterCompanyAPI";
import { Avatar } from "@designSystem/atoms/Avatar";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { invariant } from "@tools/Invariant";
import { checkAccountStatus, fetchCompanyProfiles } from "../LoginCompanyAPI";

type LoginFormType = {
	setHasErrors: (e: boolean) => void;
	hasErrors: boolean;
	setLoading: (e: boolean) => void;
	onSubmit: (e: { email: string; password: string }) => Promise<{
		token?: string;
	}>;
	loading: boolean;
	showCreateAccount?: boolean;
	className?: string;
};
export function LoginForm({
	setHasErrors,
	loading,
	setLoading,
	hasErrors,
	onSubmit,
	showCreateAccount = true,
	className = "",
}: LoginFormType) {
	const [step, setStep] = useState<1 | 2>(1);
	const [accountStatus, setAccountStatus] = useState<
		"notFound" | "unvalidated" | "ok" | "unauthorized" | "pendingAccess"
	>("ok");
	const [mailSearchLoading, setMailSearchLoading] = useState(false);
	const [defaultEmail, setDefaultEmail] = useState("");
	const [profilesModalOpen, setProfilesModalOpen] = useState(false);
	const [profiles, setProfiles] = useState<ProfileType[]>([]);
	const [searchParams] = useSearchParams();
	const { school } = useAppSelector((state: AppState) => state.company);
	useEffect(() => {
		if (searchParams.get("email")) {
			setDefaultEmail(searchParams.get("email") as string);
		}
	}, []);
	type ValuesType = {
		email?: string;
		password?: string;
	};

	const navigate = useNavigate();

	const checkEmailExists = async (email: string) => {
		try {
			invariant(checkEmail(email), "Email invalide");
			setMailSearchLoading(true);
			setAccountStatus("ok");
			const data = await checkAccountStatus(email);
			if (!data.exists) {
				setAccountStatus("notFound");
				const params = new URLSearchParams({
					subdomain: school?.subdomain,
					email,
				}).toString();
				navigate(`/company/register?${params}`);
			} else {
				setAccountStatus("ok");
				setStep(2);
			}
		} catch {
			errorToast("Une erreur est survenue");
		} finally {
			setMailSearchLoading(false);
		}
	};

	const setInterface = (token: string) => {
		setCookie("mainToken", token);
		setCookie("interface", "company");
	};

	const sendLogin = async (values: Required<ValuesType>) => {
		setHasErrors(false);
		setLoading(true);
		try {
			const { token } = await onSubmit(values);
			localStorage.setItem("email", values.email);
			if (token) {
				setCookie("mainToken", token);
				const profiles = await fetchCompanyProfiles();
				if (profiles.length === 1) {
					setCookie("token", profiles[0].token);
					setCookie("interface", "company");
					location.href = `/company?${school?.subdomain || ""}`;
				} else if (profiles.length > 1) {
					setProfilesModalOpen(true);
					setProfiles(profiles);
				} else {
					// USER HAS CORE_USER BUT NO ENT_EMPLOYEE
					const { token: newToken } = await createEntEmployee({
						onLogin: true,
						email: values.email,
					});
					setCookie("token", newToken as string);
					setCookie("interface", "company");
					location.href = `/company?${school?.subdomain || ""}`;
				}
			} else {
				setLoading(false);
				setHasErrors(true);
				return;
			}
		} catch {
			setLoading(false);
			setHasErrors(true);
		}
	};
	return (
		<>
			<Formik
				initialValues={{
					email: defaultEmail,
					password: "",
				}}
				onSubmit={async (values: Required<ValuesType>) => {
					if (step !== 2) return;
					await sendLogin(values);
				}}
				validate={(values) => {
					const errors: ValuesType = {};
					if (!values.email || !checkEmail(values.email)) {
						errors.email = "Veuillez entrer une adresse email valide";
					}
					if (!values.password) {
						errors.password = "Veuillez entrer un mot de passe";
					}
				}}
				enableReinitialize={true}
			>
				{({ values, handleChange, handleSubmit, errors, setFieldValue }) => (
					<WhiteCard
						className="my-md"
						title="Se connecter ou créer un compte"
						content={
							<form
								id="form-login"
								className={`w-full ${className}`}
								onSubmit={handleSubmit}
							>
								{loading ? (
									<div className="mx-auto mt-lg flex w-full flex-col items-center">
										<Spinner />
										<p className="mt-2 text-xxxsm text-primary-500">
											Veuillez patienter...
										</p>
									</div>
								) : (
									<>
										<div className="ml-0 mt-lg w-full">
											<Input
												errorMessage={errors.email || undefined}
												label="Email"
												onChange={handleChange}
												value={values.email}
												name="email"
												type="email"
												placeholder="Votre adresse e-mail professionnelle"
												disabled={step === 2 || accountStatus !== "ok"}
											/>
											{accountStatus === "unvalidated" && (
												<p className="text-[0.875rem]">
													Cette adresse email est associé à un compte
													non-validé.{" "}
													<InlineButton
														id="validate-account"
														label="Cliquez ici pour valider votre compte."
														onClick={() =>
															navigate(
																`/company/set_password?${
																	school?.subdomain || ""
																}&email=${values.email}`,
															)
														}
													/>
												</p>
											)}
											{accountStatus === "pendingAccess" && (
												<p className="text-[0.875rem] text-error-dark">
													Votre demande d'adhésion à l'entreprise ENT est en
													cours de traitement.
												</p>
											)}
											{accountStatus === "unauthorized" && (
												<p className="text-[0.875rem] text-error-dark">
													Votre demande d'adhésion à l'entreprise ENT a été
													refusée.{" "}
													<InlineButton
														id="modify-email"
														label="Modifier mon adresse email"
														onClick={() => {
															setFieldValue("password", "");
															setStep(1);
															setAccountStatus("ok");
														}}
													/>
												</p>
											)}
											{accountStatus === "notFound" && (
												<p className="text-[0.875rem] text-error-dark">
													Cette adresse email n'est associée à aucun
													utilisateur.
												</p>
											)}
											{(accountStatus === "notFound" || step === 2) && (
												<InlineButton
													id="modify-email"
													label="Modifier mon adresse email"
													onClick={() => {
														setFieldValue("password", "");
														setStep(1);
														setAccountStatus("ok");
													}}
												/>
											)}
											{step === 2 && accountStatus === "ok" && (
												<PasswordInput
													label="Mot de passe"
													errorMessage={
														(errors.password as string) || undefined
													}
													name="password"
													className="mt-sm"
													onChange={handleChange}
												/>
											)}
										</div>
										{hasErrors && (
											<p className="text-label-size pt-sm text-error-dark">
												Email ou mot de passe incorrect
											</p>
										)}
										{step === 1 ? (
											accountStatus !== "notFound" ? (
												<ButtonPrimary
													id="next"
													name="next"
													disabled={
														!values.email ||
														!checkEmail(values.email) ||
														mailSearchLoading
													}
													className="mx-auto mt-md"
													onClick={() => {
														checkEmailExists(values?.email);
													}}
													label="Suivant"
												/>
											) : (
												showCreateAccount && (
													<ButtonPrimary
														label="Créer votre compte"
														disabled={
															!values.email ||
															!checkEmail(values.email) ||
															mailSearchLoading
														}
														className="mx-auto mt-md"
														onClick={() =>
															navigate(
																`/company/register?${
																	school?.subdomain || ""
																}&email=${values.email}`,
															)
														}
													/>
												)
											)
										) : (
											<ButtonPrimary
												id="login"
												name="submit"
												disabled={
													!values.email ||
													!checkEmail(values.email) ||
													!values.password
												}
												className="mx-auto mt-md"
												type="submit"
												label="Se connecter"
												onClick={undefined}
											/>
										)}
										<div className="mt-sm flex justify-center gap-sm">
											{step === 2 && (
												<InlineButton
													onClick={() => {
														navigate(
															`/company/forgot_password?${
																school?.subdomain || ""
															}&email=${values.email}`,
														);
													}}
													disabled={loading}
													label="Mot de passe oublié ?"
												/>
											)}
										</div>
									</>
								)}
							</form>
						}
					/>
				)}
			</Formik>
			<Modal
				title="Choisissez l'espace entreprise à rejoindre"
				onClose={() => {
					setProfilesModalOpen(false);
					setProfiles([]);
					setLoading(false);
				}}
				show={profilesModalOpen}
				body={
					<div className="h-3/4 flex gap-xlg flex-wrap justify-between p-sm">
						{profiles.map((profile) => {
							return (
								<div
									className="flex flex-col gap-xsm items-center cursor-pointer hover:scale-105 transition-all"
									onClick={() => {
										setInterface(profile.token);
										location.href = `/company?${school?.subdomain || ""}`;
									}}
								>
									<Avatar
										image={profile.avatar}
										firstname={profile.name}
										size="xl"
										className="border-[3px] border-white shadow-xlg"
									/>
									<p className="font-semibold">
										{profile.name} - Espace entreprise
									</p>
								</div>
							);
						})}
					</div>
				}
			/>
		</>
	);
}
