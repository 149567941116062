import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { PageFilterName } from "../model/pageFilterName";

export type FiltersState = {
	currentSearch: Record<PageFilterName, string>;
};

const initialState: FiltersState = {
	currentSearch: {
		offers: "",
		companies: "",
		events: "",
		studentManagement: "",
	},
};

export const filtersSlice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		setCurrentSearch: (
			state,
			action: PayloadAction<{ pageFilterName: PageFilterName; value: string }>,
		) => {
			state.currentSearch[action.payload.pageFilterName] = action.payload.value;
		},
	},
});

export const filtersReducer = filtersSlice.reducer;
export const filtersActions = filtersSlice.actions;
