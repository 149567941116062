import React, { useState } from "react";

import { closeOutline } from "@assets/Icons";

import { checkEmail } from "@tools/Checkers";

import type { BackgroundColorType } from "@/types/colorsType";
import { Badge } from "@designSystem/atoms/Badge";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";

type MultiEmailInputType = {
	title: string;
	className?: string;
	errorMessage?: string;
	selectedEmails: string[];
	disabled?: boolean;
	setSelectedEmails: (items: string[]) => void;
	bannedEmails?: string[];
};

export function MultiEmailInput({
	selectedEmails,
	setSelectedEmails,
	title,
	errorMessage,
	disabled = false,
	className = "",
	bannedEmails,
}: MultiEmailInputType) {
	const [emailInputValue, setEmailInputValue] = useState<string>("");

	const colors = [
		"bg-emerald-light",
		"bg-terracotta-light",
		"bg-sakura-light",
		"bg-kaki-light",
		"bg-aquamarine-light",
		"bg-sunflower-light",
		"bg-lavender-light",
		"bg-stone-light",
	];

	const getBadgeColor = (index: number) => {
		return colors[index % colors.length];
	};

	const addNewEmail = (e: any) => {
		if (disabled) return;
		const mailValue = emailInputValue.trim();
		if (
			checkEmail(mailValue) &&
			!selectedEmails.includes(mailValue) &&
			!bannedEmails?.includes(mailValue)
		) {
			e.preventDefault();
			const newEmails = [...selectedEmails];
			newEmails.push(mailValue);
			setEmailInputValue("");
			setSelectedEmails(newEmails);
		}
	};
	return (
		<div
			className={className}
			onBlur={(e) => {
				addNewEmail(e);
			}}
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.key === " ") {
					addNewEmail(e);
				}
			}}
		>
			<p className="text-primary-200 text-xxsm text-center mb-sm">
				Votre message
			</p>
			<p className="font-bold mb-xsm">{title}</p>
			<InputLargePrimary
				errorMessage={errorMessage}
				placeholder="Entrez une adresse email"
				value={emailInputValue}
				disabled={disabled}
				onChange={(e) => {
					setEmailInputValue(e.target.value);
				}}
			/>
			<p className="font-bold mt-xsm">
				{selectedEmails.length} destinaire
				{selectedEmails.length > 1 && "s"} saisi
				{selectedEmails.length > 1 && "s"}
			</p>
			{selectedEmails.length ? (
				<div className="flex gap-xsm flex-wrap">
					{selectedEmails.map((selectedEmail, index) => (
						<Badge
							key={`${index} - ${selectedEmail}`}
							rightIcon={closeOutline}
							bgColor={getBadgeColor(index) as BackgroundColorType}
							label={selectedEmail}
							size="medium"
							onClick={() => {
								const newEmails = [...selectedEmails];
								newEmails.splice(index, 1);
								setSelectedEmails(newEmails);
							}}
						/>
					))}
				</div>
			) : null}
		</div>
	);
}
