import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router";
import { useOutletContext } from "react-router-dom";

import { ScrollableTabGroup } from "@designSystem/molecules/TabGroup";

import type { ViewType } from "../../Students/StudentsManagement";

import { errorToast } from "@tools/Toasts";
import { extractIdNumberFromId } from "@tools/Users";
import { queryMainData, querySchoolInfo } from "../core/api/Partners.api";
import { StatCardLoader } from "../ui/StatCard/Loader";
import { StatCard } from "../ui/StatCard/StatCard";
import { AccountsTab } from "../ui/Tabs/Accounts/AccountsTab";
import { InvitesTab } from "../ui/Tabs/Invites/InvitesTab";
import { OffersTab } from "../ui/Tabs/Offers/OffersTab";

export function PartnersIndex() {
	type MainInfosType = {
		invitations: { total: number; pending: number };
		profiles: { total: number; pending: number };
		offers: { total: number; pending: number };
	};

	const [activeTab, setActiveTab] = useState<number | null>(null);
	const [school, setSchool] = useState({});
	const [mainInfos, setMainInfos] = useState<MainInfosType>({
		invitations: { total: 0, pending: 0 },
		profiles: { total: 0, pending: 0 },
		offers: { total: 0, pending: 0 },
	});
	const [mainInfosLoading, setMainInfosLoading] = useState(true);
	const [schoolLoading, setSchoolLoading] = useState(true);

	const { user } = useContext(GlobalContext);
	const params = useParams();
	const {
		setTitle,
	}: { structureView: ViewType; setTitle: (title: string) => void } =
		useOutletContext();

	const getSchoolInfo = async () => {
		if (!user) {
			errorToast("Une erreur d'authentification est survenue");
			return;
		}
		try {
			const schoolData = await querySchoolInfo(user.school);
			setSchool(schoolData.data.school);
			setSchoolLoading(false);
		} catch {
			errorToast("Une erreur est survenue");
		}
	};
	const getMainInfo = async () => {
		if (!user) {
			errorToast("Une erreur d'authentification est survenue");
			return;
		}
		try {
			const info = await queryMainData(extractIdNumberFromId(user.school));
			setMainInfos(info.data);
			setMainInfosLoading(false);
			return info;
		} catch (e) {
			errorToast("Une erreur est survenue");
			throw new Error(e);
		}
	};
	useEffect(() => {
		setTitle("Espace entreprises partenaires");
		if (params.tab) {
			switch (params.tab) {
				case "invites":
					setActiveTab(0);
					break;
				case "accounts":
					setActiveTab(1);
					break;
				case "offers":
					setActiveTab(2);
					break;
				default:
					setActiveTab(0);
			}
		} else setActiveTab(0);
		getMainInfo();
	}, []);

	useEffect(() => {
		setSchoolLoading(true);
		getMainInfo();
		getSchoolInfo();
	}, [activeTab]);

	const tabs = [
		{ label: "Inviter", active: activeTab === 0 },
		{ label: "Comptes", active: activeTab === 1 },
		{ label: "Offres", active: activeTab === 2 },
	];

	const renderTab = () => {
		switch (activeTab) {
			case 0:
				return <InvitesTab school={school} loadingInfos={schoolLoading} />;
			case 1:
				return <AccountsTab />;
			case 2:
				return <OffersTab />;
			default:
				return (
					<div className="flex gap-md w-full">
						<div className="flex flex-col gap-sm w-full">
							<Skeleton height={160} />
							<Skeleton count={3} />
						</div>
						<div className="flex flex-col gap-sm w-full">
							<Skeleton count={5} />
							<Skeleton height={160} />
						</div>
					</div>
				);
		}
	};

	return (
		<main>
			<div className="col-span-4 md:col-span-8 lg:col-span-12 mb-xlg">
				{mainInfosLoading ? (
					<div className="flex gap-sm">
						{Array(3)
							.fill("stat-loader-")
							.map((e, index) => {
								const nbr = index;
								return <StatCardLoader key={e + nbr} />;
							})}
					</div>
				) : (
					<div className="flex gap-sm">
						<StatCard
							title="Invitations envoyées"
							stat={mainInfos.invitations.total}
							subStat={`${mainInfos.invitations.pending} en attente`}
							subStatBgColor="bg-warning-light"
						/>
						<StatCard
							title="Nombre de comptes"
							stat={mainInfos.profiles.total}
						/>
						<StatCard
							title="Nombre d'offres"
							stat={mainInfos.offers.total}
							subStat={
								mainInfos.offers.pending > 0
									? `${mainInfos.offers.pending} à traiter`
									: undefined
							}
						/>
					</div>
				)}
				<ScrollableTabGroup
					isLoading={schoolLoading}
					className="mb-md mt-lg"
					onTabChange={(e) => {
						setActiveTab(e);
					}}
					tabs={tabs}
				/>
				{renderTab()}
			</div>
		</main>
	);
}
