import React from "react";

import { searchFill } from "@assets/Icons";

import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";
import type { PageFilterName } from "@modules/filters/core/model/pageFilterName";
import { useSearchbar } from "./useSearchbar";

type SearchbarProps = {
	pageFilterName: PageFilterName;
};
export function Searchbar({ pageFilterName }: SearchbarProps) {
	const presenter = useSearchbar(pageFilterName);

	return (
		<InputLargePrimary
			icon={searchFill}
			placeholder="Rechercher..."
			onChange={presenter.modifySearch}
			value={presenter.inputValue}
			type="text"
			className="!w-max"
			containerClassName={"max-w-fit"}
		/>
	);
}
