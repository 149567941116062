import type { EntCompanyInviteStatusType } from "@/types/EntCompanyTypes";
import type { CellType } from "@/types/TableTypes";
import type {
	BackgroundColorType,
	BorderColorType,
	TextColorType,
} from "@/types/colorsType";
import type { CompaniesCellNameType } from "@/types/companiesManagement.types";
import type { EntOfferStatusSelectionOption } from "@/types/offerTypes";

export const ENT_COMPANY_INVITE_STATUS: {
	value: EntCompanyInviteStatusType;
	label: string;
	bgColor: BackgroundColorType;
}[] = [
	{ value: "error", label: "Erreur", bgColor: "bg-error-light" },
	{ value: "delivered", label: "Envoyée", bgColor: "bg-primary-100" },
	{ value: "opened", label: "Ouverte", bgColor: "bg-info-light" },
	{ value: "created", label: "Compte créé", bgColor: "bg-success-light" },
];
export const ENT_COMPANY_OFFER_STATUS: EntOfferStatusSelectionOption[] = [
	{
		value: "pending",
		label: "En attente",
		bgColor: "bg-warning-light",
		default: true,
	},
	{
		value: "active",
		label: "En cours",
		bgColor: "bg-success-light",
	},
	{
		value: "expired",
		label: "Terminée",
		bgColor: "bg-stone-light",
	},
	{
		value: "new",
		label: "Nouveaux",
		bgColor: "bg-error-light",
	},
	{
		value: "toTreatSchool",
		label: "À traiter",
		bgColor: "bg-error-light",
	},
	{
		value: "refused",
		label: "Refusée",
		bgColor: "bg-stone-light",
	},
];

export const DEFAULT_COMPANIES_TABLE_CONFIG: CompaniesCellNameType[] = [
	"name",
	"relation",
	"spsReferent",
	"location",
	"onlineOffersCount",
	"offersCount",
	"createdAt",
];

export const COMPANIES_CELLS_MAPPING = new Map<
	CompaniesCellNameType | "testing",
	CellType
>([
	["name", { cellName: "name", cellLabel: "Entreprise", cellIndex: 0 }],

	["relation", { cellName: "relation", cellLabel: "Relation", cellIndex: 1 }],
	[
		"spsReferent",
		{ cellName: "spsReferent", cellLabel: "Responsable", cellIndex: 2 },
	],
	[
		"location",
		{ cellName: "location", cellLabel: "Localisation", cellIndex: 3 },
	],
	[
		"onlineOffersCount",
		{
			cellName: "onlineOffersCount",
			cellLabel: "Offres en cours",
			cellIndex: 4,
		},
	],
	[
		"offersCount",
		{ cellName: "offersCount", cellLabel: "Offres", cellIndex: 5 },
	],
	[
		"studentsPlaced",
		{
			cellName: "studentsPlaced",
			cellLabel: "Étudiants placés",
			cellIndex: 6,
		},
	],
	[
		"createdAt",
		{ cellName: "createdAt", cellLabel: "Date de création", cellIndex: 7 },
	],
	["testing", { cellName: "testing", cellLabel: "Testing", cellIndex: 42 }],
]);

export const COMPANY_RELATIONS_OPTIONS = [
	{ value: 1, label: "Prospect" },
	{ value: 0, label: "Client" },
];

export const COMPANY_RELATIONS_MAPPING = new Map<
	number,
	{
		label: string;
		shortLabel: string;
		bgColor: BackgroundColorType;
		textColor: TextColorType;
		borderColor: BorderColorType;
	}
>([
	[
		1,
		{
			label: "Prospect",
			shortLabel: "Prospect",
			bgColor: "bg-primary-100",
			borderColor: "border-primary-500",
			textColor: "text-primary-500",
		},
	],
	[
		0,
		{
			label: "Client",
			shortLabel: "Client",
			bgColor: "bg-accent-1-lighter",
			borderColor: "border-accent-1-dark",
			textColor: "text-accent-1-dark",
		},
	],
]);
