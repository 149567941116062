import type { NavigationType } from "@navigation/type";
import React, { useState } from "react";

import {
	archiveFill,
	archiveOutline,
	awardFill,
	awardOutline,
	bookOpenFill,
	bookOpenOutline,
	briefcaseFill,
	briefcaseOutline,
	brushOutline,
	bulbFill,
	bulbOutline,
	compassFill,
	compassOutline,
	globe2Outline,
	globe3Fill,
	gridFill,
	gridOutline,
	peopleFill,
	peopleOutline,
	pieChartFill,
	pieChartOutline,
	questionMarkCircleFill,
	questionMarkCircleOutline,
	radioFill,
	radioOutline,
	sparklesFill,
	sparklesOutline,
	trendingUpOutline,
	videoFill,
	videoOutline,
} from "@assets/Icons";

import type { ConnexionInterfaceType } from "@/types/userTypes";
import { NavigationBar } from "@designSystem/organisms/NavigationBar/NavigationBar";
type MenuNavigationType = {
	currentInterface: ConnexionInterfaceType;
	user: any;
};
const AssistedGenerationBlockedSchools = [
	"/student/schools/24",
	"/student/schools/193",
	"/student/schools/194",
	"/student/schools/371",
];

const AssistedGenerationBypassEmailDomains = [
	"ace-education.com",
	"igensia.com",
	"ext.igensia.com",
];

export function MenuNavigation({ user, currentInterface }: MenuNavigationType) {
	const [showPrepera] = useState<boolean>(true);
	const navigation: NavigationType[] = [
		{
			icon: pieChartOutline,
			activeIcon: pieChartFill,
			label: "Tableau de bord",
			link: "/dashboard",
			exact: true,
			interface: ["school"],
		},
		{
			icon: trendingUpOutline,
			activeIcon: trendingUpOutline,
			label: "Statistiques",
			link: "/statistics",
			interface: ["school"],
		},
		{
			icon: pieChartOutline,
			activeIcon: pieChartFill,
			label: "Mon dashboard",
			link: "/profile",
			exact: true,
			interface: ["student"],
			visible: true,
		},
		{
			icon: peopleOutline,
			activeIcon: peopleFill,
			label: "Mes étudiants",
			link: "/students",
			interface: ["school"],
			visible: user,
			subNavigation: [
				{
					label: "Gestion des étudiants",
					link: "/students",
				},
				{
					label: "Gestion des promotions",
					link: "/promotions",
					visible: user,
				},
			],
		},
		{
			icon: globe2Outline,
			activeIcon: globe3Fill,
			label: "Relations entreprises",
			link: "/offers",
			interface: ["school"],
			visible: user,
			subNavigation: [
				{
					label: "Mes offres",
					link: "/offers",
				},
				{
					label: "CVthèque",
					link: "/cvboard",
				},
				{
					label: "Partage de profils",
					link: "/links",
				},
			],
		},
		{
			icon: bookOpenOutline,
			activeIcon: bookOpenFill,
			label: "Prospection",
			link: "/contact",
			interface: ["school"],
			visible: user,
			subNavigation: [
				{
					label: "Contacts",
					link: "/contacts",
					visible: user,
				},
				{
					label: "Annuaire école",
					link: "/school-network",
				},
				{
					label: "Annuaire général",
					link: "/school-spontaneous-application",
				},
			],
		},
		{
			icon: videoOutline,
			activeIcon: videoFill,
			label: "Évènements",
			link: "/events",
			interface: ["school"],
		},
		{
			icon: gridOutline,
			activeIcon: gridFill,
			label: "Accompagnement",
			interface: ["school"],
			visible: true,
			link: "/cv-models",
			subNavigation: [
				{
					label: "Ressources emploi",
					link: "/school-resources",
				},
				{
					label: "Modèles de CV",
					link: "/school-resources-cv-models",
				},
				{
					label: "Documents de motivation",
					link: "/school-resources-motivation-letter",
				},
				{
					label: "Mentor Goal Academy",
					link: "/school-mentor-goal-academy",
				},
				{
					label: "Communauté",
					link: "/school-community",
					invisible: user.communityLink,
				},
			],
		},
		{
			icon: briefcaseOutline,
			activeIcon: briefcaseFill,
			label: "Jobthèque",
			interface: ["school"],
			visible: true,
			separator: true,
			link: "/own-offers",
			subNavigation: [
				{
					label: "Offres école",
					link: "/own-offers",
				},
				{
					label: "Offres Jobboard",
					link: "/school-jobboard",
				},
			],
		},
		{
			icon: archiveOutline,
			activeIcon: archiveFill,
			label: "Gestion des tags",
			link: "/tags",
			interface: ["school"],
			visible: user,
		},

		{
			icon: bulbOutline,
			activeIcon: bulbFill,
			label: "Vos suggestions",
			link: "https://mentorgoal-school.canny.io/feature-requests",
			interface: ["school"],
		},
		{
			icon: questionMarkCircleOutline,
			activeIcon: questionMarkCircleFill,
			label: "Centre d'aide",
			link: "/school-help",
			interface: ["school"],
			visible: user,
			subNavigation: [
				{
					label: "Aide pour l'école",
					link: "/school-help",
				},
				{
					label: "Aide pour les étudiants",
					link: "/school-student-help",
				},
				{
					label: "Nous contacter",
					link: "/contact-us",
				},
				{
					label: "Nouveautés",
					link: "/news",
				},
			],
		},
		{
			icon: bookOpenOutline,
			activeIcon: bookOpenFill,
			label: "Mes candidatures",
			link: "/applications",
			interface: ["student"],
			visible: true,
		},
		{
			icon: sparklesOutline,
			activeIcon: sparklesFill,
			label: "Génération par IA",
			interface: ["student"],
			link: "/assisted-generation",
			invisible:
				AssistedGenerationBlockedSchools.includes(user.school) &&
				!AssistedGenerationBypassEmailDomains.includes(
					user.email.split("@")[1],
				),
		},
		{
			icon: radioOutline,
			activeIcon: radioFill,
			label: "Candidatures spontanées",
			link: "/student-network",
			interface: ["student"],
		},
		{
			icon: briefcaseOutline,
			activeIcon: briefcaseFill,
			label: "Jobthèque",
			interface: ["student"],
			visible: true,
			link: "/school-offers",
			subNavigation: [
				{
					label: "Offres école",
					link: "/school-offers",
				},
				{
					label: "Offres Jobboard",
					link: "/jobboard",
				},
			],
		},
		{
			icon: gridOutline,
			activeIcon: gridFill,
			label: "Mes ressources",
			interface: ["student"],
			visible: true,
			link: "/resources",
			subNavigation: [
				{
					label: "Ressources emploi",
					link: "/resources",
				},
				{
					label: "Modèles de CV",
					link: "/cv-models",
				},
				{
					label: "Documents de motivation",
					link: "/motivation-letter",
				},
			],
		},
		{
			icon: videoOutline,
			activeIcon: videoFill,
			label: "Évènements",
			link: "/events-student",
			interface: ["student"],
		},

		{
			icon: awardOutline,
			activeIcon: awardFill,
			label: "Mentor Goal Academy",
			link: "/mentor-goal-academy",
			separator: !user?.communityLink && true,
			interface: ["student"],
			visible: true,
		},
		{
			icon: compassOutline,
			activeIcon: compassFill,
			label: "Préparer mon entretien",
			invisible: !showPrepera,
			link: "/prepera",
			interface: ["student"],
			visible: true,
			separator: true,
		},
		{
			icon: questionMarkCircleOutline,
			activeIcon: questionMarkCircleFill,
			label: "Aide",
			link: "/student-help",
			interface: ["student"],
			visible: true,
		},
		{
			icon: bulbOutline,
			activeIcon: bulbFill,
			label: "Vos suggestions",
			link: "https://mentorgoal-student.canny.io/feature-requests",
			interface: ["student"],
			visible: true,
			external: true,
		},
		{
			icon: peopleOutline,
			activeIcon: peopleFill,
			label: "Mes candidats",
			link: "/ats",
			exact: true,
			interface: ["ats"],
			visible: true,
		},
	].filter((navigationItem) => {
		return (
			currentInterface &&
			navigationItem.interface.includes(currentInterface) &&
			!navigationItem.invisible
		);
	});

	if (
		user.roles.includes("ROLE_BETA_1") ||
		user.roles.includes("ROLE_BETA_2") ||
		user.roles.includes("ROLE_BETA_3")
	) {
		const betaSubnav = [];
		if (user.roles.includes("ROLE_BETA_2")) {
			betaSubnav.push({
				label: "Gestion des entreprises",
				link: "/crm-companies",
			});
		}
		if (user.roles.includes("ROLE_BETA_3")) {
			betaSubnav.push({
				label: "Entreprises partenaires",
				link: "/partners",
			});
		}
		navigation.push({
			icon: brushOutline,
			activeIcon: brushOutline,
			label: "Bêta",
			link: "/crm-companies",
			interface: ["school"],
			subNavigation: betaSubnav,
		});
	}

	return <NavigationBar navigation={navigation} />;
}
