import { useBreakpoints } from "@hooks/useBreakpoints";
import React, { type ReactNode, useEffect } from "react";

import { closeFill } from "@assets/Icons";

import { ButtonQuaternary } from "@designSystem/atoms/ButtonQuaternary";

type ModalLargeType = {
	body: ReactNode;
	buttonsLeft?: Array<ReactNode>;
	title: string | ReactNode;
	subtitle?: string | ReactNode;
	onClose: Function;
	buttonsRight?: Array<ReactNode>;
	id?: string;
	className: string;
	size?: string;
	translateTitle?: boolean;
	bodyHeight?: string;
};

function ModalLarge({
	body,
	buttonsLeft,
	title,
	subtitle,
	onClose,
	buttonsRight,
	id,
	className,
	size,
	translateTitle = true,
}: ModalLargeType) {
	const getWidth = () => {
		switch (size) {
			case "lg":
				return "w-[1160px]";
			default:
				return "w-[720px]";
		}
	};

	useEffect(() => {}, [size]);

	return (
		<div
			className="fixed left-0 top-0 z-20 hidden h-screen w-screen items-center justify-center bg-primary-700P/50 md:flex"
			id={`modal-${id}`}
		>
			<div
				className={`${className} flex flex-col h-fit max-h-screen rounded-lg bg-white p-md pt-lg ${getWidth()}`}
			>
				<div className="flex justify-between pb-md">
					<p
						className={`text-sm font-bold text-primary-700P ${
							!translateTitle ? "wg-no-translate" : ""
						}`}
					>
						{title} <span className="text-primary-200">{subtitle}</span>
					</p>
					<ButtonQuaternary
						icon={closeFill}
						onClick={(e: any) => {
							onClose(e);
						}}
					/>
				</div>
				<div
					id="modal-body"
					className={`relative flex-1 h-full ${
						buttonsLeft || buttonsRight
							? "max-h-[calc(100vh-200px)]"
							: "max-h-[calc(100vh-100px)]"
					} overflow-y-auto`}
				>
					{body}
				</div>
				{buttonsLeft || buttonsRight ? (
					<div className="relative flex justify-between bg-white pt-xlg">
						<div className="flex gap-xsm">
							{buttonsLeft?.map((btn, i) => {
								return (
									<div className="flex align-middle" key={i}>
										{btn}
									</div>
								);
							})}
						</div>
						<div className="flex items-center gap-xsm">
							{buttonsRight?.map((btn, i) => {
								return <div key={i}>{btn}</div>;
							})}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
}

type ModalSmallType = {
	body: ReactNode;
	buttonsRight?: Array<ReactNode>;
	title: string | ReactNode;
	onClose: Function;
	subtitle?: string | ReactNode;
	className: string;
	translateTitle?: boolean;
};

function ModalSmall({
	body,
	buttonsRight,
	title,
	onClose,
	subtitle,
	className,
	translateTitle = true,
}: ModalSmallType) {
	return (
		<div
			className={
				"fixed left-0 top-0 z-20 flex h-[calc(100dvh)] w-screen items-end bg-primary-700P/50 lg:hidden"
			}
		>
			<div
				className={`${className} flex max-h-[calc(90dvh)] w-[100vw] flex-col justify-between rounded-t-lg bg-white p-sm`}
			>
				<div>
					<div className="flex justify-between pb-md items-center mb-md ">
						<p
							className={`text-center text-primary-900 flex-1 ${
								!translateTitle ? "wg-no-translate" : ""
							}`}
						>
							{title} <span className="text-primary-200">{subtitle}</span>
						</p>

						<ButtonQuaternary
							icon={closeFill}
							onClick={(e: any) => {
								onClose(e);
							}}
						/>
					</div>
					<div className="max-h-[calc(60dvh)] overflow-y-auto">{body}</div>
				</div>
				<div
					className={
						"flex h-fit w-full flex-col items-center justify-center gap-xsm pt-sm md:mt-0 "
					}
				>
					{buttonsRight?.map((btn, i) => {
						return (
							<div className="w-full [&>*]:w-full" key={i}>
								{btn}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

type ModalType = {
	body: ReactNode;
	buttonsLeft?: Array<ReactNode>;
	title: string | ReactNode;
	subtitle?: string | ReactNode;
	show: boolean;
	onClose: Function;
	buttonsRight?: Array<ReactNode>;
	id?: string;
	className?: string;
	size?: string;
	translateTitle?: boolean;
	forceLarge?: boolean;
};

export function CustomModal({
	translateTitle = true,
	body,
	buttonsLeft,
	title,
	subtitle,
	show,
	onClose,
	buttonsRight,
	id,
	className,
	size,
	forceLarge = false,
}: ModalType) {
	const { isMobile } = useBreakpoints(799);

	useEffect(() => {
		const body = document.getElementsByTagName("body")[0];
		if (body) {
			if (show) {
				body.classList.add("overflow-hidden");
			} else {
				body.classList.remove("overflow-hidden");
			}
		}

		const handleKeyUp = (e: KeyboardEvent) => {
			if (e.key === "Escape" && show) {
				if (onClose) onClose();
			}
		};
		document.addEventListener("keyup", handleKeyUp);
		return () => {
			document.removeEventListener("keyup", handleKeyUp);
		};
	}, [show]);
	return (
		<>
			{show && (
				<>
					{!isMobile || forceLarge ? (
						<ModalLarge
							body={body}
							buttonsLeft={buttonsLeft}
							title={title}
							subtitle={subtitle}
							onClose={onClose}
							buttonsRight={buttonsRight}
							id={id}
							className={`${className} ${show ? "" : ""}`}
							size={size}
							translateTitle={translateTitle}
						/>
					) : (
						<ModalSmall
							body={body}
							buttonsRight={buttonsRight}
							title={title}
							onClose={onClose}
							subtitle={subtitle}
							className={`${className} ${show ? "" : ""}`}
							translateTitle={translateTitle}
						/>
					)}
				</>
			)}
		</>
	);
}
