import { bulbOutline } from "@assets/Icons";
import { getAssistedGenerationCards } from "@containers/student/AssistedGeneration/AssistedGenerationAPI";
import { AssistedGenerationModal } from "@containers/student/AssistedGeneration/ui/AssistedGenerationModal";
import { Spinner } from "@designSystem/atoms/Spinner";
import { InformationBanner } from "@designSystem/templates/informationBanner/InformationBanner";
import { errorToast } from "@tools/Toasts";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

const steps = [
	"Préparer ma recherche",
	"Candidature",
	"Relance",
	"Entretien",
] as const;
export type CardType = {
	id: string;
	name: string;
	description: string;
	color: string;
	tag: string;
	step: number;
	position: number;
	variablesArray: string[];
	systemPrompt: string;
};

export const AssistedGeneration = () => {
	const { setTitle }: { setTitle: Function } = useOutletContext();
	const [cards, setCards] = useState<Record<number, CardType[]>>({});
	const [selectedCard, setSelectedCard] = useState<CardType | null>(null);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setTitle("Génération par IA");
		setLoading(true);
		getCards();
	}, []);
	const getCards = async (): Promise<void> => {
		const response = await getAssistedGenerationCards();
		if (response.responseLabel !== "success") {
			errorToast("Une erreur est survenue lors de la récupération des cartes");
			setLoading(false);
			return;
		}
		// separate cards by step
		const cards = response.data.spsIACards.collection.reduce(
			(acc: Record<number, CardType[]>, card: CardType) => {
				const step = card.step;
				if (!acc[step]) {
					acc[step] = [];
				}
				acc[step].push(card);
				return acc;
			},
			{} as Record<number, CardType[]>,
		);
		setCards(cards);
		setLoading(false);
	};
	return (
		<main id="assisted-generation" className="flex flex-col gap-md">
			<InformationBanner
				icon={bulbOutline}
				className="mb-sm"
				body={
					<p>
						Libérez votre potentiel avec l'IA : générez facilement des CV
						professionnels, des lettres de motivation et bien plus encore pour
						atteindre vos objectifs de carrière !
					</p>
				}
			/>
			{loading ? (
				<div className="flex justify-center items-center w-full h-full flex-col gap-4">
					<Spinner />
					Chargement en cours...
				</div>
			) : null}
			{steps.map((step, index) =>
				cards[index + 1] ? (
					<div className="flex flex-col gap-md" key={`step-${index}`}>
						<Step key={index} title={step} index={index + 1} />
						<div className="flex gap-sm flex-wrap">
							{cards[index + 1]?.map((card) => (
								<Card
									key={card.id}
									tag={card.tag}
									color={card.color}
									title={card.name}
									description={card.description}
									onClick={() => {
										setSelectedCard(card);
									}}
								/>
							))}
						</div>
					</div>
				) : null,
			)}
			<AssistedGenerationModal
				show={selectedCard !== null}
				card={selectedCard}
				onClose={() => setSelectedCard(null)}
			/>
		</main>
	);
};

type StepProps = {
	title: string;
	index: number;
};
const Step = ({ title, index }: StepProps) => {
	return (
		<p className="text-sm font-semibold uppercase text-primary-500">
			Étape {index} : {title}
		</p>
	);
};

type CardProps = {
	tag: string;
	color: string;
	title: string;
	description: string;
	onClick?: (() => void) | undefined;
};

const Card = ({
	tag,
	color,
	title,
	description,
	onClick = undefined,
}: CardProps) => {
	return (
		<div
			className={`flex flex-col gap-sm border-2 border-${color} py-4 px-6 rounded-lg bg-white w-full slg:max-w-[49%] lg:max-w-[32%] ${onClick ? "cursor-pointer" : ""}`}
			onClick={onClick}
		>
			<div className="flex items-center gap-2">
				<p
					className={`rounded-full px-2 py-1 text-xxsm font-bold text-primary-700P bg-${color}`}
				>
					{tag}
				</p>
			</div>
			<div className="flex flex-col gap-xsm">
				<p className="text-xsm font-bold text-primary-700P">{title} :</p>
				<p className="text-xs text-primary-700P">{description}</p>
			</div>
		</div>
	);
};
