import type { EntOffersType } from "@/types/EntCompanyTypes";
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import { editOutline, emailOutline } from "@assets/Icons";

import { checkEmail } from "@tools/Checkers";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Input } from "@designSystem/molecules/Input";

import { updateEntOffer } from "@containers/school/Partners/core/api/Partners.api";

import { errorToast, success } from "@tools/Toasts";

export function EmailInfo({
	activeOffer,
	isCompany,
}: {
	activeOffer: EntOffersType;
	isCompany: boolean;
}) {
	const [editEmail, setEditEmail] = useState(false);
	const [emailValue, setEmailValue] = useState(activeOffer.emailPreference!);
	const [originalEmail, setOriginalEmail] = useState(
		activeOffer.emailPreference,
	);
	const [editEmailLoading, setEditEmailLoading] = useState(false);

	const saveEmail = async () => {
		try {
			setEditEmailLoading(true);
			await updateEntOffer({ id: activeOffer.id, emailPreference: emailValue });
			success("Nouvelle adresse email sauvegardée.");
			setOriginalEmail(emailValue);
		} catch {
			errorToast("Une erreur est survenue lors de la modification.");
			setEmailValue(originalEmail as string);
		} finally {
			setEditEmail(!editEmail);
			setEditEmailLoading(false);
		}
	};
	useEffect(() => {
		setEmailValue(activeOffer.emailPreference as string);
		setOriginalEmail(activeOffer.emailPreference as string);
	}, [activeOffer]);

	return (
		<div className="mt-sm flex items-center">
			{editEmail ? (
				<div className="flex w-full">
					<Input
						className="mr-sm w-full"
						value={emailValue}
						icon={emailOutline}
						onChange={(e) => {
							setEmailValue(e.target.value);
						}}
					/>
					<ButtonPrimary
						className="mr-xsm"
						disabled={editEmailLoading || !checkEmail(emailValue)}
						label="Modifier"
						onClick={() => {
							saveEmail();
						}}
					/>
					<ButtonSecondary
						disabled={editEmailLoading}
						label="Annuler"
						onClick={() => {
							setEditEmail(false);
							setEmailValue(originalEmail as string);
						}}
					/>
				</div>
			) : (
				<div className="flex text-[14px]">
					<span className="font-bold text-primary-300">Email de contact :</span>
					<span className="ml-xsm flex items-center">
						{emailValue}
						{isCompany && (
							<button
								type="button"
								className="ml-xsm"
								onClick={() => {
									setEditEmail(!editEmail);
								}}
							>
								<Icon icon={editOutline} />
							</button>
						)}
					</span>
				</div>
			)}
		</div>
	);
}
