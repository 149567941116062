import type { SchoolType } from "@/types/schoolTypes";
import { SchoolCard } from "@containers/company/Layout/NoSchool/ui/SchoolCard";
import { SchoolCardLoader } from "@containers/company/Layout/NoSchool/ui/SchoolCardLoader";
import { WhiteCard } from "@designSystem/molecules/WhiteCard";

import React, { useState } from "react";

export function NoSchool() {
	const [schoolsLoading] = useState<boolean>(false);
	const [schools] = useState<Partial<SchoolType>[]>([]);
	const [noEmployee] = useState<boolean>(false);

	return (
		<>
			<WhiteCard
				className="flex w-full flex-col mt-sm"
				title="Les écoles déjà contactées"
				content={
					schoolsLoading ? (
						[1, 2, 3].map((num) => <SchoolCardLoader key={`loader-${num}`} />)
					) : noEmployee ? (
						<p>Vous n'avez pas de profil employé.</p>
					) : schools.length === 0 ? (
						<p>Aucune école trouvée</p>
					) : (
						schools.map((school) => (
							<SchoolCard key={school.id} school={school} />
						))
					)
				}
			/>
		</>
	);
}
