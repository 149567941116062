import type {
	EntCompanySchoolProfileType,
	EntOffersType,
} from "@/types/EntCompanyTypes";
import type { SchoolOfferType } from "@/types/offerTypes";
import React, { useState } from "react";
import { useNavigate } from "react-router";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Spinner } from "@designSystem/atoms/Spinner";

import { OfferTypeSelectionModal } from "@containers/school/Offers_v3/ui/modal/OfferTypeSelectionModal";
import { updateEntOffer } from "@containers/school/Partners/core/api/Partners.api";

import { updateOfferStatus } from "@containers/company/slice/companySlice";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { errorToast, success } from "@tools/Toasts";
import { useDispatch } from "react-redux";

export function SchoolHeader({ activeOffer }: { activeOffer: EntOffersType }) {
	const navigate = useNavigate();
	const dispatchEvent = useDispatch();

	const [showOfferRefusalModal, setShowOfferRefusalModal] = useState(false);
	const [refusalLoading, setRefusalLoading] = useState(false);
	const [showOfferTypeSelection, setShowOfferTypeSelection] = useState(false);
	const [modalOfferType, setModalOfferType] = useState("preselection");

	const refuseOffer = async () => {
		const offerUpdate: Partial<EntOffersType> = {
			id: activeOffer.id,
			status: "refused",
		};
		setRefusalLoading(true);
		try {
			await updateEntOffer(offerUpdate);
			dispatchEvent(
				updateOfferStatus({ offer: activeOffer, newStatus: "refused" }),
			);
			success("L'offre a bien été refusée");
		} catch {
			errorToast("Une erreur est survenue lors du refus");
		} finally {
			setRefusalLoading(false);
			setShowOfferRefusalModal(false);
		}
	};

	return (
		<>
			{" "}
			{activeOffer.status === "pending" ? (
				<>
					<div className="flex items-center gap-xsm">
						<ButtonSecondary
							label="Refuser l'offre"
							onClick={() => {
								setShowOfferRefusalModal(true);
							}}
						/>
						<ButtonPrimary
							label="Publier l'offre"
							onClick={() => {
								setShowOfferTypeSelection(true);
							}}
						/>
					</div>
				</>
			) : (
				activeOffer.status !== "refused" &&
				activeOffer.spsOffer && (
					<ButtonPrimary
						onClick={() => {
							navigate(
								`/offers/details/${
									(activeOffer.spsOffer as SchoolOfferType).id.split("/")[3]
								}`,
							);
						}}
						label="Voir l'offre"
					/>
				)
			)}
			<Modal
				onClose={() => {
					setShowOfferRefusalModal(false);
				}}
				title="Refuser l'offre"
				show={showOfferRefusalModal}
				body={
					<div className="flex flex-col">
						<p>
							Êtes-vous sûr de vouloir refuser l'offre <b>{activeOffer.job}</b>{" "}
							de{" "}
							<b>
								{
									(
										activeOffer.entCompanySchoolProfile as EntCompanySchoolProfileType
									).entCompany.name
								}
							</b>{" "}
							?
						</p>
						{refusalLoading && (
							<Spinner className="mx-auto mt-md" size="small" />
						)}
					</div>
				}
				buttonsRight={[
					<ButtonSecondary
						key="cancel"
						disabled={refusalLoading}
						label="Annuler"
						onClick={() => {
							setShowOfferRefusalModal(false);
						}}
					/>,
					<ButtonPrimary
						key="refuse"
						disabled={refusalLoading}
						label="Oui, refuser"
						onClick={async () => {
							await refuseOffer();
						}}
					/>,
				]}
			/>
			<Modal
				onClose={() => {
					setShowOfferTypeSelection(false);
				}}
				title="Créer l'offre"
				show={showOfferTypeSelection}
				body={
					<OfferTypeSelectionModal
						onChange={(newType) => {
							setModalOfferType(newType);
						}}
					/>
				}
				buttonsRight={[
					<ButtonSecondary
						key="cancel"
						label="Annuler"
						onClick={() => {
							setShowOfferTypeSelection(false);
						}}
					/>,
					<ButtonPrimary
						key="next"
						label="Suivant"
						onClick={() => {
							navigate("/offers/creation", {
								state: {
									entOffer: {
										id: activeOffer.id,
										type: modalOfferType,
										company: (
											activeOffer.entCompanySchoolProfile as EntCompanySchoolProfileType
										).entCompany,
									},
								},
							});
						}}
					/>,
				]}
			/>
		</>
	);
}
