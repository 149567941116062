import { useAppSelector } from "@config/redux/hook";
import { type AppState, useAppDispatch } from "@config/redux/store";
import useDebounce from "@hooks/useDebounce";
import type { PageFilterName } from "@modules/filters/core/model/pageFilterName";
import {
	type FiltersState,
	filtersActions,
} from "@modules/filters/core/store/filters.slice";
import { useEffect, useState } from "react";

export const useSearchbar = (pageFilterName: PageFilterName) => {
	const modifySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const cleanValue = e.target.value.replace(";", "");
		e.target.value = cleanValue;
		setSearch(cleanValue);
	};

	const { currentSearch } = useAppSelector(
		(state: AppState) => state.filters as FiltersState,
	);

	const dispatchEvent = useAppDispatch();

	const [search, setSearch] = useState<string>(
		currentSearch[pageFilterName] ?? "",
	);
	const debouncedValue = useDebounce(search);

	useEffect(() => {
		dispatchEvent(
			filtersActions.setCurrentSearch({
				pageFilterName,
				value: debouncedValue,
			}),
		);
	}, [debouncedValue]);

	return {
		search,
		modifySearch,
		inputValue: currentSearch
			? currentSearch[pageFilterName]?.replace("%", " ")
			: "",
	};
};
