import { useAppSelector } from "@config/redux/hook";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { plusOutline } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { OffersFilters } from "@modules/offersFilters";
import { OffersfiltersSearchbar } from "@modules/offersFilters/OffersfiltersSearchbar";
import { ResetOffersFiltersButton } from "@modules/offersFilters/ResetOffersFiltersButton";
import { ToggleOffersFilters } from "@modules/offersFilters/ToggleOffersFilters";

import { clearOfferToEdit } from "../../core/store/offersManagement.slice";
import { OfferTypeSelectionModal } from "../modal/OfferTypeSelectionModal";

export function OffersListingHeader() {
	const dispatchEvent = useDispatch();
	const navigation = useNavigate();
	const { offerToCreate } = useAppSelector((state) => state.offers);
	const { currentFilters, useFilters } = useAppSelector(
		(state) => state.offersFilters,
	);
	const [showTypeSelectionModal, setShowTypeSelectionModal] =
		useState<boolean>(false);

	const toggleTypeSelectionModal = () => {
		setShowTypeSelectionModal(!showTypeSelectionModal);
	};

	const openCreationPage = async () => {
		await dispatchEvent(clearOfferToEdit() as any);
		navigation(`/offers/creation?type=${offerToCreate?.type}`);
	};

	const opencvboard = () => {
		navigation("/cvboard");
	};
	return (
		<section className="flex" data-testid="offersHeader">
			<div className="flex w-full flex-col justify-between gap-sm">
				<div className="flex w-full justify-between gap-md">
					<div className="flex w-7/12 max-w-fit items-center justify-start  gap-4 sm:flex-row">
						<OffersfiltersSearchbar />
						<div className="flex gap-xxsm">
							<ToggleOffersFilters />
							<ResetOffersFiltersButton />
						</div>
					</div>
					<div data-testid="offersAddButton">
						<ButtonPrimary
							icon={plusOutline}
							label="Publier une offre"
							className=""
							onClick={toggleTypeSelectionModal}
						/>
					</div>
				</div>
				{!!useFilters && (
					<OffersFilters
						filtersProps={{ filters: currentFilters, setFilters: () => {} }}
					/>
				)}
				<div className="flex w-full justify-center gap-4 md:w-4/12 md:justify-end md:gap-sm" />
			</div>
			<Modal
				title="Quel type d'offre souhaitez-vous créer ?"
				show={showTypeSelectionModal}
				onClose={toggleTypeSelectionModal}
				body={<OfferTypeSelectionModal />}
				buttonsRight={[
					<ButtonSecondary
						key="cancel"
						label="Annuler"
						onClick={toggleTypeSelectionModal}
					/>,
					<ButtonPrimary
						key="cvboard"
						label="Aller à la CVthèque"
						onClick={opencvboard}
						className={
							offerToCreate && offerToCreate?.type === "shared"
								? "!block"
								: "!hidden"
						}
					/>,
					<ButtonPrimary
						key="next"
						label="Suivant"
						onClick={openCreationPage}
						className={
							offerToCreate && offerToCreate?.type === "shared"
								? "!hidden"
								: "!block"
						}
					/>,
				]}
			/>
		</section>
	);
}
