import { briefcaseOutline, bulbOutline, sparklesFill } from "@assets/Icons";
import type { CardType } from "@containers/student/AssistedGeneration";
import { generateAssistedGeneration } from "@containers/student/AssistedGeneration/AssistedGenerationAPI";
import { CustomModal } from "@containers/student/AssistedGeneration/ui/CustomModal";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { RichTextEditor } from "@designSystem/atoms/RichTextEditor";
import { Spinner } from "@designSystem/atoms/Spinner";
import { TextArea } from "@designSystem/atoms/TextArea";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";
import { DatePicker } from "@designSystem/organisms/DatePicker";
import { InformationBanner } from "@designSystem/templates/informationBanner/InformationBanner";
import { errorToast, success } from "@tools/Toasts";
import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";

type AssistedGenerationModalProps = {
	show: boolean;
	card: CardType | null;
	onClose: () => void;
};

const AVAILABLE_VARIABLES = {
	sentDate: {
		name: "Date d'envoi",
		description: "Date de la candidature",
		maxLength: 10,
		type: "date",
		icon: null,
	},
	jobName: {
		name: "Nom du poste",
		description: "Nom du poste",
		maxLength: 255,
		type: "text",
		icon: null,
	},
	offerDescription: {
		name: "Description de l'offre",
		description: "Description de l'offre",
		maxLength: 7000,
		type: "textarea",
		icon: null,
	},
	companyName: {
		name: "Nom de l'entreprise",
		description: "Nom de l'entreprise",
		maxLength: 255,
		type: "text",
		icon: briefcaseOutline,
	},
	userFormation: {
		name: "Description d'une formation",
		description: "Description d'une formation",
		maxLength: 700,
		type: "text",
		icon: null,
	},
	userExperiencePro: {
		name: "Description d'une expérience professionnelle",
		description: "Description d'une expérience professionnelle",
		maxLength: 700,
		type: "text",
		icon: null,
	},
	userProjects: {
		name: "Projets",
		description: "Projets",
		maxLength: 1000,
		type: "text",
		icon: null,
	},
	userCV: {
		name: "Copier coller les éléments de votre CV",
		description: "Copier coller les éléments de votre CV",
		maxLength: 1000,
		type: "text",
		icon: null,
	},
	interviewDate: {
		name: "Date d'entretien",
		description: "Date d'entretien",
		maxLength: 10,
		type: "date",
		icon: null,
	},
} as const;

type variablesValuesType = Record<keyof typeof AVAILABLE_VARIABLES, string>;

export const AssistedGenerationModal = ({
	show,
	card,
	onClose,
}: AssistedGenerationModalProps) => {
	const [generatedData, setGeneratedData] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [variablesValues, setVariablesValues] = useState<variablesValuesType>(
		{} as variablesValuesType,
	);

	const formRef = useRef<HTMLFormElement | null>(null);
	const richTextEditorRef = useRef<ReactQuill | null>(null);
	const OnModalClose = () => {
		setGeneratedData("");
		setVariablesValues({} as variablesValuesType);
		setLoading(false);
		onClose();
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!card) return;
		setLoading(true);
		let error = false;
		// check if variables are not too long
		card.variablesArray.forEach(
			(variable: keyof typeof AVAILABLE_VARIABLES) => {
				// check if the variable is in the available variables
				if (!AVAILABLE_VARIABLES[variable]) {
					errorToast(`${variable} n'est pas disponible`);
					error = true;
					return;
				}
				// check if the trimmed variable is not empty
				if (
					!variablesValues[variable] ||
					variablesValues[variable].trim() === ""
				) {
					errorToast(
						`${AVAILABLE_VARIABLES[variable].name} ne peut pas être vide`,
					);
					error = true;
					return;
				}
				if (
					variablesValues[variable].length >
					AVAILABLE_VARIABLES[variable].maxLength
				) {
					errorToast(
						`${AVAILABLE_VARIABLES[variable].name} ne peut pas dépasser ${AVAILABLE_VARIABLES[variable].maxLength} caractères`,
					);
					error = true;
					return;
				}
			},
		);

		if (error) {
			setLoading(false);
			return;
		}

		const response = await generateAssistedGeneration(variablesValues, card.id);
		if (response.status !== 200) {
			if (response.status === 429) {
				errorToast(
					"Vous avez atteint le nombre maximal de générations par heure",
				);
			} else {
				errorToast(
					"Une erreur est survenue lors de la génération de la lettre de motivation",
				);
			}
			setLoading(false);
			return;
		}
		setGeneratedData(response.data.message.replace(/\n/g, "<br>"));
		setLoading(false);
	};
	const skipIndex: number[] = [];
	return (
		<CustomModal
			title={card?.name}
			show={show}
			onClose={OnModalClose}
			buttonsRight={
				!generatedData
					? [
							<ButtonPrimary
								icon={sparklesFill}
								className={"self-end"}
								label="Générer par IA"
								type={"submit"}
								disabled={loading}
								onClick={() => {
									formRef?.current?.requestSubmit();
								}}
							/>,
						]
					: [
							<ButtonSecondary
								label={"Précédent"}
								onClick={() => {
									setGeneratedData("");
								}}
							/>,
							<ButtonPrimary
								label={"Copier le texte"}
								onClick={() => {
									if (!(richTextEditorRef?.current instanceof ReactQuill))
										return;
									const editor = richTextEditorRef.current.getEditor();
									navigator.clipboard.writeText(editor.getText()).then(() => {
										success("Le texte a été copié dans le presse-papier");
									});
								}}
							/>,
						]
			}
			body={
				<div className="flex flex-col gap-4">
					{loading ? (
						<div className="flex justify-center items-center w-full h-full flex-col gap-4">
							<Spinner />
							Génération en cours...
						</div>
					) : !generatedData ? (
						<form
							onSubmit={handleSubmit}
							className={"flex flex-col gap-4"}
							ref={formRef}
						>
							<InformationBanner
								icon={bulbOutline}
								className="mt-[-24px]"
								body={<p>{card?.description}</p>}
							/>
							{card?.variablesArray.map((variable, index) => {
								if (skipIndex.includes(index)) return null;
								const openDiv =
									index + 1 < card?.variablesArray.length &&
									AVAILABLE_VARIABLES[
										variable as keyof typeof AVAILABLE_VARIABLES
									].type === "text" &&
									AVAILABLE_VARIABLES[
										card?.variablesArray[
											index + 1
										] as keyof typeof AVAILABLE_VARIABLES
									].type === "text";
								const currentField = (
									<FormField
										key={variable}
										variable={variable as keyof typeof AVAILABLE_VARIABLES}
										loading={loading}
										setVariablesValues={setVariablesValues}
										variablesValues={variablesValues}
									/>
								);
								if (openDiv) {
									skipIndex.push(index + 1);
									return (
										<div
											className="flex gap-2 flex-col md:flex-row"
											key={`${variable}-${index}`}
										>
											{currentField}
											<FormField
												key={card?.variablesArray[index + 1]}
												variable={
													card?.variablesArray[
														index + 1
													] as keyof typeof AVAILABLE_VARIABLES
												}
												loading={loading}
												setVariablesValues={setVariablesValues}
												variablesValues={variablesValues}
											/>
										</div>
									);
								}
								return currentField;
							})}
						</form>
					) : (
						<RichTextEditor
							className="whitespace-pre-line"
							value={generatedData}
							onChange={(e) => setGeneratedData(e)}
							placeholder={""}
							modules={{
								toolbar: false,
								clipboard: {
									matchVisual: false,
								},
							}}
							quillRef={richTextEditorRef}
						/>
					)}
				</div>
			}
		/>
	);
};

const FormField = ({
	variable,
	loading,
	variablesValues,
	setVariablesValues,
}: {
	variable: keyof typeof AVAILABLE_VARIABLES;
	loading: boolean;
	variablesValues: variablesValuesType;
	setVariablesValues: React.Dispatch<React.SetStateAction<variablesValuesType>>;
}) => {
	if (!AVAILABLE_VARIABLES[variable]) return null;
	const info = AVAILABLE_VARIABLES[variable];
	if (info.type === "date")
		return (
			<DatePicker
				withIcon
				label={`${info.name}*`}
				selectedDate={new Date(variablesValues[variable] ?? "")}
				onDateChange={(date) =>
					setVariablesValues({
						...variablesValues,
						[variable]: date.toISOString().split("T")[0],
					})
				}
			/>
		);
	if (info.type === "textarea")
		return (
			<TextArea
				label={`${info.name}*`}
				value={variablesValues[variable] ?? ""}
				onChange={(value) =>
					setVariablesValues({ ...variablesValues, [variable]: value })
				}
				placeholder={info.description}
				disabled={loading}
				maxLength={info.maxLength}
			/>
		);
	return (
		<InputLargePrimary
			icon={info.icon}
			onChange={(e) =>
				setVariablesValues({ ...variablesValues, [variable]: e.target.value })
			}
			value={variablesValues[variable] ?? ""}
			label={`${info.name}*`}
			name={variable}
			placeholder={info.description}
			disabled={loading}
		/>
	);
};
