import type { ReducersMapObject } from "@reduxjs/toolkit";

import offersFiltersSlice from "@modules/offersFilters/offersFiltersSlice";
import usersFiltersSlice from "@modules/usersFilters/usersFiltersSlice";

import companySlice from "@containers/company/slice/companySlice";
import { companiesManagemenReducer } from "@containers/school/Companies/core/store/companiesManagement.slice";
import mailBoxSlice from "@containers/school/Mailbox/controller/mailBox.slice";
import offersSlice from "@containers/school/Offers_v3/core/store/offersManagement.slice";
import { referentsReducer } from "@containers/school/Referents/core/store/referents.slice";
import studentsManagementSlice from "@containers/school/Students/StudentsManagement/studentsManagementSlice";
import { eventsReducer } from "@containers/shared/Events/core/store/events.slice";
import jobboardSlice from "@containers/student/Jobboard/core/store/jobboard.slice";
import OfferDetailSlice from "@containers/student/Jobboard/core/store/offerDetail.slice";
import { filtersReducer } from "@modules/filters/core/store/filters.slice";

export const REDUX_SLICES: ReducersMapObject = {
	filters: filtersReducer,
	events: eventsReducer,
	usersFilters: usersFiltersSlice,
	studentsManagement: studentsManagementSlice,
	offers: offersSlice,
	company: companySlice,
	mailBox: mailBoxSlice,
	offersFilters: offersFiltersSlice,
	companiesManagement: companiesManagemenReducer,
	jobboard: jobboardSlice,
	offerDetail: OfferDetailSlice,
	referents: referentsReducer,
};
