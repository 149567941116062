import React, { useContext, useState } from "react";

import { plusOutline } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Modal } from "@designSystem/organisms/modal/Modal";

import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import { checkIfCompanyCouldBeCreated } from "@tools/Companies";
import { buildTooltipFromStackErrors } from "@tools/Errors";
import { updateToastWithError, updateToastWithSuccess } from "@tools/Toasts";
import { toast } from "react-toastify";

import { useAppDispatch } from "@config/redux/store";
import { fetchCreateCompany } from "@containers/school/Companies/core/api/companiesManagement.request";
import { companiesManagementSlice } from "@containers/school/Companies/core/store/companiesManagement.slice";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { Searchbar } from "@modules/filters/ui/sections/Searchbar";
import { CompanyCreationModal } from "./CompanyCreation.modal";

export function CompaniesListingHeader() {
	const setDataWasModified =
		companiesManagementSlice.actions.setDataWasModified;
	const creationModalWasClosed =
		companiesManagementSlice.actions.creationModalWasClosed;
	const dispatchEvent = useAppDispatch();
	const { user } = useContext(GlobalContext);
	const { companyToCreate, companyNameAlreadyExist, companySiretAlreadyExist } =
		useAppSelector((state) => state.companiesManagement);
	const [showCreationModal, setShowCreationModal] = useState<boolean>(false);

	const toggleCreationModal = () => {
		setShowCreationModal(!showCreationModal);
	};

	const handleAbortCreation = () => {
		dispatchEvent(creationModalWasClosed());
		toggleCreationModal();
	};

	const handleCompanyCreation = async () => {
		const id = toast.loading("Création en cours...", {
			isLoading: true,
			hideProgressBar: false,
			type: "default",
		});
		const serverResponseForCompanyCreation = await fetchCreateCompany(
			companyToCreate,
			user?.school || "",
		);
		if (serverResponseForCompanyCreation.responseLabel === "success") {
			dispatchEvent(creationModalWasClosed());
			toggleCreationModal();
			updateToastWithSuccess(id, "Entreprise ajoutée avec succès");
			dispatchEvent(setDataWasModified(true));
		} else {
			updateToastWithError(id, "Erreur lors de l'ajout de l'entreprise");
		}
	};

	return (
		<section className="flex" data-testid="offersHeader">
			<div className="flex w-full flex-col justify-between gap-sm">
				<div className="flex w-full justify-between gap-md">
					<div className="flex w-7/12 max-w-fit items-center justify-start  gap-4 sm:flex-row">
						<Searchbar pageFilterName="companies" />
						<div className="flex gap-xxsm">
							{/* ADD TOGGLE COMPANIES FILTER HERE */}
							{/* ADD RESET COMPANIES FILTER BUTTON HERE */}
						</div>
					</div>
					<div data-testid="companiesAddButton">
						<ButtonPrimary
							icon={plusOutline}
							label="Ajouter une entreprise"
							className=""
							onClick={toggleCreationModal}
						/>
					</div>
				</div>
				{/* ADD COMPANIES FILTER BLOC HERE */}
				<div className="flex w-full justify-center gap-4 md:w-4/12 md:justify-end md:gap-sm" />
			</div>
			<TooltipContainer
				anchorId={"#creationbutton"}
				makeClickable={false}
				children={buildTooltipFromStackErrors(
					checkIfCompanyCouldBeCreated(
						companyToCreate,
						companyNameAlreadyExist,
						companySiretAlreadyExist,
					),
				)}
				place="top"
			/>
			<Modal
				title="Ajouter manuellement une entreprise"
				useScroll={false}
				show={showCreationModal}
				onClose={handleAbortCreation}
				body={<CompanyCreationModal />}
				buttonsRight={[
					<ButtonSecondary label="Annuler" onClick={handleAbortCreation} />,
					<div className="flex gap-2">
						<ButtonPrimary
							label="Ajouter"
							onClick={handleCompanyCreation}
							disabled={
								checkIfCompanyCouldBeCreated(
									companyToCreate,
									companyNameAlreadyExist,
									companySiretAlreadyExist,
								).length > 0
							}
						/>
						{checkIfCompanyCouldBeCreated(
							companyToCreate,
							companyNameAlreadyExist,
							companySiretAlreadyExist,
						)?.length !== 0 && (
							<TooltipElement id="creationbutton" type="alert" />
						)}
					</div>,
				]}
			/>
		</section>
	);
}
