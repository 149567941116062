import { errorToast } from "@tools/Toasts";

/**
 * @description: 🛡️ A guard using invariant pattern. The guard checks a specific condition.
 * If the condition draws to true, the guard prevents some functionality from executing and throws an error.
 * @param condition
 * @param message
 * @example https://github.com/facebook/react/blob/v17.0.0/packages/shared/invariant.js
 */
export const invariant = (condition: boolean, message: string) => {
	if (!condition) {
		errorToast(message);
		throw new Error(message);
	}
};
